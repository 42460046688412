<template>
  <div class="main" :style="{width:`${datainfo.resolutionOne}px`,height:`${datainfo.resolutionTwo}px`}">
    <div :id="`iframe${index}`" v-for="(item,index) in datainfo.screenOne * datainfo.screenTwo" :key="index">
      <iframe width="100%" height="100%" class="frame" id="iframeId"></iframe>
    </div>
  </div>
</template>

<script>
import {getByStatus} from "../../api/screen/show";
import {nextTick, onMounted, reactive, toRefs} from "vue";
export default {
  setup(){
    let state = reactive({
      datainfo:[]
    })

    //分隔数组
    function group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while(index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    }

    onMounted(()=>{
      let arr = [];
      let _ar = "";
      getByStatus().then(res=>{
        state.datainfo = res.data.data[0];
        JSON.parse(state.datainfo.content).forEach(item=>{
          console.log(item)
          let _ramdom = (Math.random()*1000).toFixed(0);
          item.k.forEach((e,index)=>{
            arr[e] = `areas${_ramdom}`;
            nextTick(()=>{
              document.getElementById(`iframe${e}`).style.gridArea = `areas${_ramdom}`;
              document.getElementById(`iframe${Math.max(...item.k)}`).childNodes[0].setAttribute('src',item.url);
            })
          })
        })

        group(arr, Number(state.datainfo.screenOne)).forEach(item=>{
          _ar += `'${item.join(' ')}'`;
        })

        nextTick(()=>{
          document.body.style.setProperty('--columns', `repeat(${state.datainfo.screenOne},1fr)`);
          document.body.style.setProperty('--rows', `repeat(${state.datainfo.screenTwo},1fr)`);
          document.body.style.setProperty('--areas', _ar);
        })
      })

    })
    return{
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.main{
  display: grid;
  grid-template-columns: var(--columns);
  grid-template-rows: var(--rows);
  grid-template-areas:  var(--areas);
  .frame{
    border: medium none;
    object-fit: cover;
  }
}
</style>